import Notification from "./Notification";
import Loader from "./Loader";
import Routing from "../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";
import Table from "./Table";

const routes = require("../../../public/js/fos_js_routes.json");

Routing.setRoutingData(routes);

export default class Products {
    constructor() {
        this.thirdNumberInput = $("#create_form_thirdNumber");
        this.thirdNumberChoice = $(".third-number-choice");
        this.customNumberInput = $("#create_form_customNumber");
        this.customNumberChoice = $(".custom-number-choice");
        this.coaIdInput = $("#create_form_licenseSerialNumber");
        this.serialNumber = $("#create_form_serialNumber");
        this.coaIdChoice = $(".coa-id-choice");
        this.submitButton = $("#create_form_submit");
        this.productAddForm = $(".product.form.create-form");
        this.productAddSoundSuccess = $('.product-add-sound-success');
        this.productAddSoundFail = $('.product-add-sound-success');

    }

    /**
     * @returns {jQuery}
     * @param $list
     */
    createListTable($list) {
        const TableObject = new Table();

        let route = {
            "url": Routing.generate("product_list"),
            "type": "POST"
        };
  
        let all = $list.attr("data-all");

        if (all) {
            route.url = Routing.generate("product_list_all");
        }

        let bdo = $list.attr("data-bdo");

        if (bdo) {
            route.url = Routing.generate("product_list_bdo");
        }

        let quarantined = $list.attr("data-quarantined");

        if (quarantined) {
            route.url = Routing.generate("product_list_show_quarantined");
        }

        let revenueShareOnly = $list.attr("data-revenueShareOnly");

        if (revenueShareOnly) {
            route.url = Routing.generate("product_list_revenue_share_only");
        }

        return TableObject.createTable($list, {
            ajax: route,
            columnDefs: [
                { targets: 0, orderable: false },
                { name: "p.serialNumber", targets: 2 }, // Product->serialNumber
            { name: "s.iaiCode", targets: 3 }, // Sku->shortName
            { name: "sku", targets: 4 },
                { name: "s.skuShortName", targets: 5 }, // Sku->shortName
                { name: "diagnosticCode", targets: 6, orderable: false },
                { name: "g.name", targets: 7 }, // Grade->name
                { name: "daysToSupplierCompliant", targets: 8 }, // Grade->name
                { name: "sb.name", targets: 9 }, // SubDelivery->name
                { name: "d.quarantine", targets: 10 },
                { name: "location", targets: 11 },
                { name: "p.updatedLocationAt", targets: 12 },
            { name: "p.changedWarehouseAt", targets: 13 },
            { name: "p.thirdNumber", targets: 14 }, // Product->thirdNumber
            { name: "p.customNumber", targets: 15 }, // Product->thirdNumber
            { name: "pt.name", targets: 16 }, // ProductType->name
            { name: "mn.name", targets: 17 }, // Model->name
            { name: "sd.name", targets: 18 },
            { name: "n.name", targets: 19 },
            { name: "ct.name", targets: 20 },
            { name: "cn.name", targets: 21 },
            { name: "ctk.name", targets: 22 }, 
            { name: "r.name", targets: 23 },
            { name: "hd.name", targets: 24 },
            { name: "ss.name", targets: 25 },
            { name: "sr.name", targets: 26 },
            { name: "gpu.name", targets: 27 }, // Product->gpu
            { name: "sg.name", targets: 28 },
            { name: "l.name", targets: 29 },
            { name: "p.licenseSerialNumber", targets: 30 }, // Product->licenseSerialNumber
            { name: "op.name", targets: 31 }, // OpticalDrive->name
            { name: "p.remark", targets: 32 }, // Product->name
            { name: "p.recentlyRefurbishedAt", targets: 33 },
            { name: "daysSinceCheckedIn", targets: 34 },
            { name: "p.latestPrice", targets: 35 },
            { name: "p.latestOrderPrice", targets: 36 },
            { name: "p.orderSource", targets: 37},
            { name: "lsp", targets: 40 },
            { name: "wf.name", targets: 41 },
            { name: "bl.name", targets: 42 },
            { name: "wwan.name", targets: 43 },
            { name: "cm.name", targets: 44 },
            { name: "f.name", targets: 45 },
            { name: "kl.name", targets: 46 },
            { name: "klt.name", targets: 47 },
            { name: "b.name", targets: 48 },
            { name: "b0.batteryPercentage", targets: 49 },
            { name: "b1.batteryPercentage", targets: 50 },
            { name: "b2.batteryPercentage", targets: 51 },
            { name: "b3.batteryPercentage", targets: 52 },
            { name: "b4.batteryPercentage", targets: 53 },
            { name: "b5.batteryPercentage", targets: 54 },
            { name: "m.weight", targets: 55 },
            { targets: 56, orderable: false },
            ],
            columns: [
                { className: "action", targets: 0 },
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
            null,
            null,
            null,
            null,
            null,
            ],
        });
    }

    /**
     * @param choice
     */
    toggleThirdNumber(choice) {
        let $thirdNumberInput = $(".third-number");

        $thirdNumberInput.hide();

        if ($(choice).is(":checked")) {
            $thirdNumberInput.show();
        }
    }

    /**
     * @param choice
     */
    toggleCoaId(choice) {
        let $coaIdInput = $(".coa-id");

        $coaIdInput.hide();

        if ($(choice).is(":checked")) {
            $coaIdInput.show();
        }
    }

    /**
     * @param choice
     */
    toggleCustomNumber(choice) {
        let $customNumberInput = $(".custom-number");

        $customNumberInput.hide();

        if ($(choice).is(":checked")) {
            $customNumberInput.show();
        }
    }
    /**
     *
     */
    printLabel(href) {
        window.frames[href].focus();
        window.frames[href].print();
    }

    generateChart($canvas) {
        let ctx = $canvas[0].getContext('2d');

        let labels = JSON.parse($canvas.attr('data-label'));
        let data = JSON.parse($canvas.attr('data-value'));
        let title = JSON.parse($canvas.attr('data-title'));

        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: title[0],
                        data: data[0],
                        backgroundColor: '#' + Math.floor(Math.random()*16777215).toString(16),
                    },
                    {
                        label: title[1],
                        data: data[1],
                        backgroundColor: '#' + Math.floor(Math.random()*16777215).toString(16),
                    }
            ]
            },
            options: {
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                    y: {
                        beginAtZero: true
                    },
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            userCallback: function (label) {
                                if (Math.floor(label) === label) {
                                    return label;
                                }

                            },
                        }
                    }],
                }
            }
        });
    }
    generateChart2($canvas) {
        let ctx = $('.refurbish-summary-chart-2')[0].getContext('2d');
        let labels = Object.values(JSON.parse($canvas.attr('data-label')));
        let data = JSON.parse($canvas.attr('data-value'));
        let title = JSON.parse($canvas.attr('data-title'));


        let dataSets = [];


        for (let category in data) {
            let counts = [];
            let uniques = [];

            for (let label of  labels) {
                if (label in data[category]) {
                    counts.push(data[category][label][0]);
                    uniques.push(data[category][label][1]);
                } else {
                    counts.push(null);
                    uniques.push(null);
                 }
            }
            let color = '#' + Math.floor(Math.random()*16777215).toString(16);
            dataSets.push(
                {   
                    barPercentage: 0.8,
                    categoryPercentage: 0.4,
                    // barThickness: 6,
                    // maxBarThickness: 8,
                    label: category + ' (' + title[0] + ')',
                    stack: 0,
                    // fillColor: randomColor1,
                    data: counts,
                    backgroundColor: '#' + Math.floor(Math.random()*16777215).toString(16),
                }
            ); 
            dataSets.push(
                {   
                    barPercentage: 0.8,
                    categoryPercentage: 0.4,
                    // barThickness: 6,
                    // maxBarThickness: 8,
                    label: category + ' (' + title[1] + ')',
                    stack: 1,
                    // fillColor: randomColor1,
                    data: uniques,
                    backgroundColor: '#' + Math.floor(Math.random()*16777215).toString(16),
                }
            ); 
        }

        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: dataSets,
            },
            options: {
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                    y: {
                        beginAtZero: true,
                        stacked: true,
                    },
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            userCallback: function (label) {
                                if (Math.floor(label) === label) {
                                    return label;
                                }
                            },
                        },
                        stacked: true
                    }],
                    xAxes: [{
                        stacked: true,
                    }],
                }
            }
        });
    }
    generateChart3($canvas) {
        let ctx = $('.refurbish-summary-chart-3')[0].getContext('2d');
        let labels = Object.values(JSON.parse($canvas.attr('data-label')));
        let data = JSON.parse($canvas.attr('data-value'));
        let title = JSON.parse($canvas.attr('data-title'));


        let dataSets = [];


        for (let category in data) {
            let counts = [];
            let uniques = [];

            for (let label of  labels) {
                if (label in data[category]) {
                    counts.push(data[category][label][0]);
                    uniques.push(data[category][label][1]);
                } else {
                    counts.push(null);
                    uniques.push(null);
                 }
            }
            let color = '#' + Math.floor(Math.random()*16777215).toString(16);
            dataSets.push(
                {   
                    barPercentage: 0.8,
                    categoryPercentage: 0.4,
                    // barThickness: 6,
                    // maxBarThickness: 8,
                    label: category + ' (' + title[0] + ')',
                    stack: 0,
                    // fillColor: randomColor1,
                    data: counts,
                    backgroundColor: '#' + Math.floor(Math.random()*16777215).toString(16),
                }
            ); 
            dataSets.push(
                {   
                    barPercentage: 0.8,
                    categoryPercentage: 0.4,
                    // barThickness: 6,
                    // maxBarThickness: 8,
                    label: category + ' (' + title[1] + ')',
                    stack: 1,
                    // fillColor: randomColor1,
                    data: uniques,
                    backgroundColor: '#' + Math.floor(Math.random()*16777215).toString(16),
                }
            ); 
        }

        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: dataSets,
            },
            options: {
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                    y: {
                        beginAtZero: true,
                        stacked: true,
                    },
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            userCallback: function (label) {
                                if (Math.floor(label) === label) {
                                    return label;
                                }
                            },
                        },
                        stacked: true
                    }],
                    xAxes: [{
                        stacked: true,
                    }],
                }
            }
        });
    }

    showParameterValuesByType(parameterType) {
        let $paramSelects = $('.param-select');

        $paramSelects.each(function () {
            let $selectParentBlock = $(this).closest('.form-group');
            if ($(this).data('param-type') === parameterType) {
                if ($selectParentBlock.hasClass('hidden')) {
                    $selectParentBlock.removeClass('hidden');
                }
            } else {
                $(this).val('');
                if (!$selectParentBlock.hasClass('hidden')) {
                    $selectParentBlock.addClass('hidden');
                }
            }

        });
    }

    /**
     * @returns {Models}
     */
    handleEvent() {

        let that = this;
        let $thirdNumberChoice = $(".third-number-choice");

        if ($thirdNumberChoice.length > 0) {
            $thirdNumberChoice
                .on("change", (event) => {
                    this.toggleThirdNumber(event.currentTarget);
                })
                .trigger("change");
        }

        let $coaIdChoice = $(".coa-id-choice");

        if ($coaIdChoice.length > 0) {
            $coaIdChoice
                .on("change", (event) => {
                    this.toggleCoaId(event.currentTarget);
                })
                .trigger("change");
        }

        let $customNumberChoice = $(".custom-number-choice");

        if ($customNumberChoice.length > 0) {
            $customNumberChoice
                .on("change", (event) => {
                    this.toggleCustomNumber(event.currentTarget);
                })
                .trigger("change");
        }

        let serialNumber = $(".serial-number");

        if (serialNumber.length > 0) {
            serialNumber.focus();

            serialNumber.on("keyup", (event) => {
                event.preventDefault();

                let keyCode = event.keyCode || event.which;

                if (keyCode === 13) {
                    if (this.thirdNumberChoice.is(":checked")) {
                        this.thirdNumberInput.focus();
                    } else if (this.coaIdChoice.is(":checked")) {
                        this.coaIdInput.focus();
                    } else if (this.customNumberChoice.is(":checked")) {
                        this.customNumberInput.focus();
                    } else {
                        this.productAddForm.trigger('submit');
                    }
                }
            });
        }

        this.thirdNumberInput.on("keyup", (event) => {
            event.preventDefault();

            let keyCode = event.keyCode || event.which;

            if (keyCode === 13) {
                if (this.coaIdChoice.is(":checked")) {
                    this.coaIdInput.focus();
                }  else if (this.customNumberChoice.is(":checked")) {
                    this.customNumberInput.focus();
                } else {
                    this.productAddForm.trigger('submit');
                }
            }
        });

        this.coaIdInput.on("keyup", (event) => {
            event.preventDefault();

            let keyCode = event.keyCode || event.which;

            if (keyCode === 13) {
                if (this.customNumberChoice.is(":checked")) {
                    this.customNumberInput.focus();
                } else {
                    this.productAddForm.trigger('submit');
                }
            }
        });

        this.customNumberInput.on("keyup", (event) => {
            event.preventDefault();

            let keyCode = event.keyCode || event.which;

            if (keyCode === 13) {
                this.productAddForm.trigger('submit');
            }
        });

        let $printAndChangelocation = $(".print-and-change-location");

        $printAndChangelocation.on("submit", (event) => {
            event.preventDefault();

            let form = $(event.currentTarget);
            let LoaderObject = new Loader();
            let product = +form.attr("data-product");
            let value = +form.find(".shelf").val();

            let loaderSpinner = LoaderObject.createLoader($(".product-sku-info"));

            let data = new FormData();
            data.append("shelf", value);

            $.when(
                $.ajax({
                    type: "POST",
                    url: Routing.generate("product_location_save", {
                        product: product,
                    }),
                    contentType: false,
                    processData: false,
                    data: data,
                    success: (response) => {
                        LoaderObject.removeLoader(loaderSpinner);

                        const NotificationObject = new Notification();
                        NotificationObject.setSuccessAlert(response.message);

                        $('.rnew-product-info-item .location-link').html(response.newLocationPath);
                        this.printLabel("barcode");
                    },
                    error: (xhr, status, error) => {
                        LoaderObject.removeLoader(loaderSpinner);

                        const NotificationObject = new Notification();
                        NotificationObject.setErrorAlert(xhr.responseJSON.error);
                    },
                })
            );
        });

        this.productAddForm.on("submit", (event) => {
            event.preventDefault();
            let $form = $(event.currentTarget);
            const NotificationObject = new Notification();
            
            if ($form.find( "input#create_form_serialNumber" ).val() === "") {
                NotificationObject.setErrorAlert('Pole "Numer seryjny" nie może być puste!');
                return;
            }
            if ($form.find( "select#create_form_shelf" ).val() === "") {
                NotificationObject.setErrorAlert('Pole "Półka" nie może być puste!');
                return;
            }
            
            let LoaderObject = new Loader();
            let loaderSpinner = LoaderObject.createLoader($(event.currentTarget).parent());
            
            let reqUrl = Routing.generate("product_create_ajax");

            $.ajax({
                url: reqUrl,
                method: 'POST',
                data: $form.serialize(),
                success: (response) => {
                    LoaderObject.removeLoader(loaderSpinner);
                    if (response.type === "success")  {
                        this.productAddSoundSuccess[0].play();
                        NotificationObject.setSuccessAlert(response.message);
                        this.serialNumber.val("");
                        this.coaIdInput.val("");
                        this.thirdNumberInput.val("");
                        this.customNumberInput.val("");
                        this.serialNumber.focus();
                    } else {
                        this.productAddSoundFail[0].play();
                        NotificationObject.setErrorAlert(response.message);
                    }
                    
                },
                error: (xhr, status, error) => {
                    LoaderObject.removeLoader(loaderSpinner);
                    this.productAddSoundFail[0].play();
                    const NotificationObject = new Notification();
                    NotificationObject.setErrorAlert(xhr.responseJSON.error);
                },
            });
        });

        let $summaryTables = $('.refurbish-summary-chart');

        $summaryTables.each(function () {
            if ($(this).length > 0) {
                that.generateChart($(this));
            }
        });

        let $summaryTables2 = $('.refurbish-summary-chart-2');
        $summaryTables2.each(function () {
            if ($(this).length > 0) {
                that.generateChart2($(this));
            }
        });

        let $summaryTables3 = $('.refurbish-summary-chart-3');
        $summaryTables3.each(function () {
            if ($(this).length > 0) {
                that.generateChart3($(this));
            }
        });
 
        $(".product.printBtn").on("click", (event) => {
            event.preventDefault();

            let href = $(event.currentTarget).attr("href");
            href = href.split("#").join("");

            this.printLabel(href);
        });

        $(".print-simple-serial-number").on("click", (event) => {
            event.preventDefault();

            if ($('#create_form_serialNumber').val().length) {
                
                window.open(
                    Routing.generate('product_barcode', {
                        'product': encodeURIComponent($('#create_form_serialNumber').val())
                    }),
                    '_blank' // <- This is what makes it open in a new window.
                );
            } else {
                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert('Pole "Numer seryjny" nie może być puste!');
                return;
            }
        });

        this.submitButton.on("click", (event) => {
            this.productAddForm.submit();
        });

        let $productsList = $(".products-list");

        if ($productsList.length > 0) {
            this.createListTable($productsList);
        }

        let $parameterTypeSelect = $('.accessory-parameter-type-select');

        $parameterTypeSelect.on('change', (event) => {
            let $selected = $(event.currentTarget).find('option:selected');
            let parameterType = parseInt($selected.val());

            this.showParameterValuesByType(parameterType);

        }).trigger('change');
    }

    /**
     *
     */
    init() {
        this.handleEvent();
    }
}
