const routes = require('../../../public/js/fos_js_routes.json');
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import Notification from './Notification.js';
Routing.setRoutingData(routes);

export default class Modal {

    /**
     * @param modalTitle
     * @param modalContent
     * @param href
     * @param modalIdFromLink
     */
    createRemoveModal(modalTitle, modalContent, href, modalIdFromLink) {
        let modal;

        if (typeof modalIdFromLink === 'undefined') {
            modal = $('[data-modal-window="true"]');
        } else {
            modal = $('#' + modalIdFromLink + '[data-modal-window="true"]');
        }

        console.log(modal);

        modal.find('#remove-title').html(modalTitle);
        modal.find('#remove-content').html(modalContent);

        let modalId = modal.attr('id');

        MicroModal.show(modalId, {
            onShow: () => {
                this.handleConfirm(href);
            },
            onClose: (modal, element, event) => {
                event.preventDefault();
                event.stopPropagation();
            }
        });
    }

    /**
     * @param modalTitle
     * @param modalContent
     * @param href
     * @param modalIdFromLink
     */
    createCustomModal(modalTitle, modalContent, href, modalIdFromLink, handleConfirm = true) {
        let modal;

        if (typeof modalIdFromLink === 'undefined') {
            modal = $('[data-modal-window="true"]');
        } else {
            modal = $('#' + modalIdFromLink + '[data-modal-window="true"]');
        }

        console.log(modal);
        let orderInputId = '#modal_order_id';
        let orderIdInput = modal.find(orderInputId);
        modal.find('#custom-title').html(modalTitle);
        modal.find('#custom-content').html(modalContent);

        let modalId = modal.attr('id');

        MicroModal.show(modalId, {
            onShow: () => {
                if (orderIdInput.length > 0 && modalId === 'basket_verify_order') {
                    this.handleConfirmForInput(modal, href);
                } else if (handleConfirm) {
                    this.handleConfirm(href);
                }
            },
            onClose: (modal, element, event) => {
                event.preventDefault();
                event.stopPropagation();
            }
        });
    }

    /**
     * @param modalTitle
     * @param modalContent
     * @param href
     * @param modalIdFromLink
     */
    createAccValidationModal(modalTitle, modalContent, href, modalIdFromLink, form, reason) {
        let modal;

        if (typeof modalIdFromLink === 'undefined') {
            modal = $('[data-modal-window="true"]');
        } else {
            modal = $('#' + modalIdFromLink + '[data-modal-window="true"]');
        }
        modal.find('#custom-title').html(modalTitle);
        modal.find('#custom-content').html(modalContent);

        let modalId = modal.attr('id');
        MicroModal.show(modalId, {
            onShow: () => {
                let $confirmButton = $('[data-confirm]');

                $confirmButton.on('click', (event) => {
                    event.preventDefault();
                    $.when($.ajax({
                        type: 'POST',
                        url: Routing.generate('refurbish_validate_log'),
                        data: reason,
                        success:function (response) {
                            const NotificationObject = new Notification();
                            if (response.success) {
                                form.submit();
                            } else if (response.error) {
                                NotificationObject.setErrorAlert(element.error);
                            }
                        },
                        error: (xhr, status, error) => {
                            console.log(error);  
                        }
                    }));
                });                
            },
            onClose: (modal, element, event) => {
                event.preventDefault();
                event.stopPropagation();
            }
        });
    }
    /**
     */
    createBasicModal(modalIdFromLink) {
        let modal;

        if (typeof modalIdFromLink == 'undefined') {
            modal = $('[data-modal-window="true"]');
        } else {
            modal = $('#' + modalIdFromLink + '[data-modal-window="true"]');
        }

        let modalId = modal.attr('id');

        MicroModal.show(modalId, {
            onClose: function (modal, element, event) {
                event.preventDefault();
                event.stopPropagation();
            }
        });
    }

    /**
     * @param href
     */
    handleConfirm(href) {
        const $confirmButton = $('[data-confirm]');

        $confirmButton.on('click', () => {
            window.location.href = href;
        });
    }

    /**
     * @param href
     */
    handleConfirmForInput(modal, href) {
        const $confirmButton = $('[data-confirm]');

        $confirmButton.on('click', () => {
            let orderIdInput = modal.find('#modal_order_id');
            if (orderIdInput[0].value.length > 0) {
                $('#error_order_id').remove();
                $(orderIdInput[0]).removeClass('is-invalid');
                return window.location.href = href + "?order_id=" +  orderIdInput[0].value;
            } else {
                 $(orderIdInput[0]).addClass('is-invalid');
                 let error = $('<span id="error_order_id"></span>').innerHTML("Podaj nr zamówienia!"); 
                error.addClass('invalid-feedback');
                orderIdInput[0].append(error);
                return;
            }
        });
    }
    /**
     *
     */
    handleEvent() {
        $(document).on('click', '[data-modal]', (event) => {
            event.preventDefault();
            event.stopPropagation();

            let $clickedModal = $(event.currentTarget);

            let type = $clickedModal.data('type');
            let modalId = $clickedModal.attr('data-id');

            if (type === 'remove') {
                let modalTitle = $clickedModal.data('title');
                let modalContent = $clickedModal.data('content');
                let href = $clickedModal.attr('href');

                this.createRemoveModal(modalTitle, modalContent, href, modalId);
            } else if (type === 'basic') {
                this.createBasicModal(modalId);
            } else if (type === 'custom') {
                let modalTitle = $clickedModal.data('title');
                let modalContent = $clickedModal.data('content');
                let handleConfirm = $clickedModal.data('handleConfirm');
                if ($clickedModal.data('basket-name')) {
                    let basketName = $clickedModal.data('basket-name');
                    modalTitle = modalTitle.replace('${basketName}', '<span style="color:#231d76;">' + basketName + '</span>');
                    modalContent = modalContent.replace('${basketName}', '<span style="color:#231d76;">' + basketName + '</span>');

                    let modalCount = $clickedModal.data('shelf-count') ?? $clickedModal.data('basket-count');
                    modalContent = modalContent.replace('${count}', '<span style="color:#231d76;">' + modalCount + '</span>');
                }
                let href = $clickedModal.attr('href');

                this.createCustomModal(modalTitle, modalContent, href, modalId, handleConfirm);
            }
        });
    }

    init() {
        this.handleEvent();
    }
}