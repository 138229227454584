import Loader from "./Loader";
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import Notification from "./Notification";
import Table from './Table';

const routes = require('../../../public/js/fos_js_routes.json');

Routing.setRoutingData(routes);

export default class Warehouse {

    /**
     * @returns {jQuery}
     * @param $list
     */
    createAnalyseListTable($list) {
        const TableObject = new Table();

        let warehouse = $list.attr('data-warehouse');

        return TableObject.createTable($list, {
            ajax: Routing.generate('warehouse_analyse_list', {
                'warehouse': warehouse
            }),
            dom: 'lBip<"table-responsive"rt>p',
            'columnDefs': [
                {'name': 'sku', 'targets': 0},
            ],
            createdRow: function (row, data, dataIndex, cells) {
                let scanned = parseInt($(data[2]).text()[0]);
                let iaiCount = parseInt(data[1]);

                if (scanned !== iaiCount) {
                    $(cells[0]).addClass('table-danger');
                    $(cells[1]).addClass('table-danger');
                    $(cells[3]).addClass('table-danger');
                } else {
                    $(cells[0]).addClass('table-success');
                    $(cells[1]).addClass('table-success');
                    $(cells[3]).addClass('table-success');
                }

                $(row).addClass('sku-row');
            },
            columns: [
                null,
                null,
                null,
                null,
                null,
                null,
            ],
        });
    }

    /**
     * @param aisles
     */
    showAisles(aisles) {
        let $aislesSelect = $('.aisles-select');

        if (aisles.length > 0) {
            let html = '';

            for (let a in aisles) {
                html += '<option value="' + aisles[a].id + '">' + aisles[a].name + '</option>';
            }

            $aislesSelect.html(html).closest('.aisles').css('display', 'flex');
        } else {
            $aislesSelect.html('').closest('.aisles').hide();
        }
    }

    /**
     * @returns {Models}
     * @param warehouse
     */
    ajaxAisles(warehouse) {
        if (warehouse !== '') {
            let LoaderObject = new Loader();
            let loaderSpinner = LoaderObject.createLoader($('.move-cart-form'));
    
            $.when(
                
                $.ajax({
                    url: Routing.generate('warehouse_aisles_warehouse', {warehouse: warehouse}, false),
                    type: 'GET',
                    dataType: 'json',
                })
            ).then((result) => {
                this.showAisles(result);
                LoaderObject.removeLoader(loaderSpinner);

            });
        }

        return this;
    }

    /**
     * @param value
     * @param codes
     */
    validateCode(value, codes) {
        let LoaderObject = new Loader();

        let loaderSpinner = LoaderObject.createLoader($('.scan'));

        let data = new FormData();
        data.append('item', value);
        data.append('codes', codes);

        return $.when($.ajax({
            type: 'POST',
            url: Routing.generate('warehouse_product_to_shelf_validate'),
            contentType: false,
            processData: false,
            data: data,
            success: (response) => {
                LoaderObject.removeLoader(loaderSpinner);

                this.insertDataToList(response);
                this.insertDataToInput(response);

                let $scanShelfHint = $('.scan-shelf-hint');
                let $scanProductOrShelfHint = $('.scan-product-or-shelf-hint');

                if (response.data.type === 'shelf') {
                    $scanShelfHint.hide();
                    $scanProductOrShelfHint.show();
                }

                let $codes = $('.codes');
                let codesValues = $codes.val();

                codesValues = codesValues.split(',');

                if (typeof codesValues[0] !== 'undefined' && codesValues.length > 1) {
                    if (value === codesValues[0]) {
                        let $scanForm = $('.scan-form');
                        $scanForm.submit();
                    }
                }
            },
            error: (xhr, status, error) => {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error, true);
            }
        }))
    }

    /**
     * @param value
     * @param codes
     */
    cartToAisleValidateCode(value, codes) {
        let LoaderObject = new Loader();

        let loaderSpinner = LoaderObject.createLoader($('.scan'));

        let data = new FormData();
        data.append('item', value);
        data.append('codes', codes);

        return $.when($.ajax({
            type: 'POST',
            url: Routing.generate('warehouse_cart_to_aisle_validate'),
            contentType: false,
            processData: false,
            data: data,
            success: (response) => {
                LoaderObject.removeLoader(loaderSpinner);

                this.insertDataToList(response);
                this.insertDataToInput(response);

                let $scanShelfHint = $('.scan-aisle-hint');
                let $scanProductOrShelfHint = $('.scan-cart-or-aisle-hint');

                if (response.data.type === 'aisle') {
                    $scanShelfHint.hide();
                    $scanProductOrShelfHint.show();
                }

                let $codes = $('.codes');
                let codesValues = $codes.val();

                codesValues = codesValues.split(',');

                if (typeof codesValues[0] !== 'undefined' && codesValues.length > 1) {
                    if (value === codesValues[0]) {
                        let $scanForm = $('.scan-form');
                        $scanForm.submit();
                    }
                }
            },
            error: (xhr, status, error) => {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        }))
    }
    /**
     * @param response
     */
    insertDataToInput(response) {
        let $codes = $('.codes');
        let codesValues = $codes.val();

        if (codesValues !== '') {
            codesValues = codesValues.split(',');
        } else {
            codesValues = [];
        }

        if (response.data.type === 'shelf') {
            codesValues.push(response.data.shortNumber);
        }

        if (response.data.type === 'aisle') {
            codesValues.push(response.data.shortNumber);
        }
        if (response.data.type === 'cart') {
            codesValues.push(response.data.shortNumber);
        }
        if (response.data.type === 'product') {
            codesValues.push(response.data.serialNumber);
        }

        $codes.val(codesValues.join(','));
    }

    /**
     * @param response
     */
    insertDataToList(response) {
        let item = this.createItem(response.data);

        let $scannedItemList = $('.scanned-item-list');
        let $scannedProductSection = $('.scanned-product-list');

        $scannedItemList.append(item);

        if ($scannedItemList.length > 0) {
            $scannedProductSection.show();
        }
    }

    /**
     * @param response
     */
    createItem(response) {
        if (response.type === 'shelf') {
            return '<li class="scanned-shelf">' + response.name + ' <b>(' + response.cart + ')</b> <br><cite>' + response.shortNumber + '</cite><a data-type="shelf" data-href="' + response.shortNumber + '" href="#" class="rnew-action delete" ><i class="fas fa-trash"></i></a></li>';
        }

        if (response.type === 'aisle') {
            return '<li class="scanned-aisle">' + response.name + ' <b>(' + response.warehouse + ')</b> <br><cite><i class="fad fa-barcode"></i> ' + response.shortNumber + '</cite><a data-type="aisle" data-href="' + response.shortNumber + '" href="#" class="rnew-action delete" ><i class="fas fa-trash"></i></a></li>';
        }
        if (response.type === 'cart') {
            return '<li class="scanned-cart">' + response.name + ' <b>(' + response.aisle + ')</b> <br><cite><i class="fad fa-barcode"></i> ' + response.shortNumber + '</cite><a data-type="cart" data-href="' + response.shortNumber + '" href="#" class="rnew-action delete" ><i class="fas fa-trash"></i></a></li>';
        }

        if (response.type === 'product') {
            let inc = $('.scanned-product').length + 1;
            return '<li class="scanned-product"><b>' + inc + '.</b> ' + response.name + ' <b>(' + response.serialNumber + ')</b> <br><cite>' + response.subdelivery + '</cite>' + '<a data-type="product" data-href="' + response.serialNumber + '" href="#" class="rnew-action delete" ><i class="fas fa-trash"></i></a></li>';
        }
    }

    /**
     * @returns {*}
     * @param $table
     */
    addProductsFromWarehouseToBasket(basketNumber, $table) {
        let warehouse = +$table.attr('data-warehouse');
        let LoaderObject = new Loader();
        let loaderSpinner = LoaderObject.createLoader($table);

        return $.when($.ajax({
            type: 'POST',
            url: Routing.generate(
                'warehouse_warehouse_add_to_basket',
                {warehouse: warehouse, basket: basketNumber}
            ),
            contentType: false,
            processData: false,
            success: function (response) {
                function showNotification(element) {
                    const NotificationObject = new Notification();
                    if (element.success) {
                        NotificationObject.setSuccessAlert(element.success);
                    } else if (element.info) {
                        NotificationObject.setInfoAlert(element.info);
                    } else if (element.error) {
                        NotificationObject.setErrorAlert(element.error);
                    }
                }

                LoaderObject.removeLoader(loaderSpinner);
                response.forEach(showNotification);
            },
            error: function (xhr, status, error) {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        }))
    }

    productToShelf() {
        let $productToShelvesScan = $('.product-to-shelf-scan');
        $productToShelvesScan.val('').focus();

        $productToShelvesScan.on('keydown keypress', (event) => {
            let keyCode = event.keyCode || event.which;

            if (keyCode === 13) {
                event.preventDefault();

                let value = $(event.currentTarget).val();

                let codes = $(event.currentTarget).closest('form').find('.codes').val();

                this.validateCode(value, codes);

                $(event.currentTarget).val('');
                $(event.currentTarget).focus();
            }
        }).on('blur', (event) => {
            $(event.currentTarget).focus();
        });

        let $scannedListItem = $('.scanned-item-list');
        let $scanForm = $('.scan-form');
        let codes = $scanForm.find('.codes');

        codes.val('');

        $scannedListItem.on('click', '.delete', (event) => {
            event.preventDefault();

            let codesValue = codes.val();
            let type = $(event.currentTarget).attr('data-type');
            let href = $(event.currentTarget).attr('data-href');

            let $scannedProductSection = $('.scanned-product-list');

            if (type === 'shelf') {
                codes.val('');
                $scannedListItem.html('');
                $scannedProductSection.hide();

                let $scanShelfHint = $('.scan-shelf-hint');
                let $scanProductOrShelfHint = $('.scan-product-or-shelf-hint');

                $scanShelfHint.show();
                $scanProductOrShelfHint.hide();

                return false;
            }

            let codesValueArray = [];
            codesValue = codesValue.split(',');

            for (let i in codesValue) {
                if (codesValue[i] !== href) {
                    codesValueArray.push(codesValue[i]);
                }
            }

            codesValue = codesValueArray.join(',');
            codes.val(codesValue);

            $(event.currentTarget).closest('li').remove();
        });
    }

    cartToAisle() {
        let $productToShelvesScan = $('.cart-to-aisle-scan');
        $productToShelvesScan.val('').focus();

        $productToShelvesScan.on('keydown keypress', (event) => {
            let keyCode = event.keyCode || event.which;

            if (keyCode === 13) {
                event.preventDefault();

                let value = $(event.currentTarget).val();

                let codes = $(event.currentTarget).closest('form').find('.codes').val();

                this.cartToAisleValidateCode(value, codes);

                $(event.currentTarget).val('');
                $(event.currentTarget).focus();
            }
        }).on('blur', (event) => {
            $(event.currentTarget).focus();
        });

        let $scannedListItem = $('.scanned-item-list');
        let $scanForm = $('.scan-form');
        let codes = $scanForm.find('.codes');

        codes.val('');

        $scannedListItem.on('click', '.delete', (event) => {
            event.preventDefault();

            let codesValue = codes.val();
            let type = $(event.currentTarget).attr('data-type');
            let href = $(event.currentTarget).attr('data-href');

            let $scannedProductSection = $('.scanned-product-list');

            if (type === 'aisle') {
                codes.val('');
                $scannedListItem.html('');
                $scannedProductSection.hide();

                let $scanShelfHint = $('.scan-aisle-hint');
                let $scanProductOrShelfHint = $('.scan-cart-or-aisle-hint');

                $scanShelfHint.show();
                $scanProductOrShelfHint.hide();

                return false;
            }

            let codesValueArray = [];
            codesValue = codesValue.split(',');

            for (let i in codesValue) {
                if (codesValue[i] !== href) {
                    codesValueArray.push(codesValue[i]);
                }
            }

            codesValue = codesValueArray.join(',');
            codes.val(codesValue);

            $(event.currentTarget).closest('li').remove();
        });
    }

    /**
     *
     */
    handleEvent() {
        this.productToShelf();
        this.cartToAisle();
        let $warehouseSelect = $('.warehouse-select');

        if ($warehouseSelect.length > 0) {
            $warehouseSelect.on('change', (event) => {
                let warehouse = $(event.currentTarget).val();

                this.ajaxAisles(warehouse);
            }).trigger('change');
        }

        let $productSerialNumber = $('.product-serial-number');

        $productSerialNumber.val('').focus();

        $productSerialNumber.on('keydown keypress', (event) => {
            let keyCode = event.keyCode || event.which;

            if (keyCode === 13) {
                event.preventDefault();

                let value = $(event.currentTarget).val();

                if (value !== '') {
                    $(event.currentTarget).closest('form').submit();
                }
            }
        }).on('blur', (event) => {
            $(event.currentTarget).focus();
        });

        let $basketAddButton = $('.warehouse-basket-add-btn');

        let $aislesList = $('.aisles-content-table');

        $basketAddButton.on('click', (event) => {
            event.preventDefault();
            let basketNumber = $(".choose-basket-select option:selected").val();

            this.addProductsFromWarehouseToBasket(basketNumber, $aislesList);
        });

        let $warehouseAnalyseRowsList = $('.warehouse-analyse-rows-list');

        if ($warehouseAnalyseRowsList.length > 0) {
            this.createAnalyseListTable($warehouseAnalyseRowsList);
        }
    }

    /**
     *
     */
    init() {
        this.handleEvent();
    }
}