import Routing from "../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

const routes = require("../../../public/js/fos_js_routes.json");

Routing.setRoutingData(routes);

export default class Permissions {
  handleEvent() {
    let $permissions = $(".permissions");

    $permissions
      .find('input[type="checkbox"]')
      .not("input[value$=_view], input[value$=_process]")
      .parent()
      .hide();

    let $checkboxes = $permissions.find(
      "input[value$=_view], input[value$=_process]"
    );

    $checkboxes
      .on("change", (event) => {
        $(event.currentTarget)
          .closest("fieldset")
          .find('input[type="checkbox"]')
          .not("input[value$=_view], input[value$=_process]")
          .parent()
          .hide();

        if ($(event.currentTarget).is(":checked")) {
          $(event.currentTarget)
            .closest("fieldset")
            .find('input[type="checkbox"]')
            .parent()
            .show();
        } else {
          $(event.currentTarget)
            .closest("fieldset")
            .find('input[type="checkbox"]')
            .prop("checked", false);
        }
      })
      .change();

    $(document).on("change", ".form-control-role", function () {
      let group = $(this).val();
      $.ajax({
        type: "GET",
        processData: true,
        data: {
          id: group,
        },
        url: Routing.generate("user_get_permissions"),

        success: function (result) {
          result = result["permissions"];

          $(".permissions .form-check").show();
          $(".permissions .form-check-input").prop("checked", false);

          for (let i = 0; i < result.length; i++) {
              $('.permissions input[type="checkbox"][value="' + result[i] + '"]').prop("checked",true);
          }
        },
      });
    });

    return this;
  }

  init() {
    this.handleEvent();
  }
}
