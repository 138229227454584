import Loader from "./Loader";
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';

const routes = require('../../../public/js/fos_js_routes.json');

Routing.setRoutingData(routes);

export default class TinyMCE {

    createTinyMce(settings) {

        let defaultOptions = {
            mode: 'textareas',
            selector: '.tinymce-custom',
            content_css: '//www.tiny.cloud/css/codepen.min.css',
            skins: false,
            menubar: 'file format table tools',
            toolbar: 'undo redo | bbcodesSection | customBlocksBBcode customBlocksHtml addCustomBlockButton | image | alignleft aligncenter alignright alignfull | formatselect | numlist bullist | bold italic underline strikethrough | fontselect fontsizeselect | code | fullscreen',
            height: "500",
            theme: 'silver',
            entity_encoding: 'raw',
            plugins: 'visualblocks, lists, autosave, table code fullscreen image',
            visualblocks_default_state: true,
            end_container_on_empty_block: true,
            extended_valid_elements: 'lite-youtube[videoid]',
            valid_children: '+body[lite-youtube]',
            custom_elements: 'lite-youtube'
        }

        settings = $.extend(defaultOptions, settings);

        tinymce.init(settings);
    }

    /**
     *
     */
    init() {
        let LoaderObject = new Loader();
        let $tinyMceCustom = $('.tinymce-custom');

        if ($tinyMceCustom.length > 0) {
            let loaderSpinner = LoaderObject.createLoader($tinyMceCustom.parent());

            $.when(
                $.ajax({
                    url: Routing.generate('model_custom_blocks', {}, false),
                    type: 'GET',
                    dataType: 'json',
                })
            ).then((customBlocks) => {
                LoaderObject.removeLoader(loaderSpinner);

                let settings = {
                    setup: function (editor) {
                        editor.ui.registry.addMenuButton('customBlocksBbcode', {
                            text: 'CUSTOM BLOCK (BBCODE)',
                            fetch: function (callback) {
                                let items = [];
                                for (let customBlock in customBlocks) {
                                    items.push({
                                        type: 'menuitem',
                                        text: customBlocks[customBlock].name + ' ([mars:custom:' + customBlocks[customBlock].slug + '])',
                                        onAction: function (_) {
                                            editor.insertContent('[mars:custom:' + customBlocks[customBlock].slug + ']');
                                        }
                                    });
                                }

                                callback(items);
                            }
                        });

                        editor.ui.registry.addMenuButton('customBlocksHtml', {
                            text: 'CUSTOM BLOCK (HTML)',
                            fetch: function (callback) {
                                let items = [];
                                for (let customBlock in customBlocks) {
                                    items.push({
                                        type: 'menuitem',
                                        text: customBlocks[customBlock].name,
                                        onAction: function (_) {
                                            editor.insertContent(customBlocks[customBlock].code);
                                        }
                                    });
                                }

                                callback(items);
                            }
                        });

                        let systemVariablesEnabled = $tinyMceCustom.attr('data-tinymce-system-variables') || false;
                        let customBlocksAddLinkEnabled = $tinyMceCustom.attr('data-tinymce-add-custom-blocks-link') || false;

                        if (customBlocksAddLinkEnabled) {
                            editor.ui.registry.addButton('addCustomBlockButton', {
                                text: 'NOWY CUSTOM BLOCK',
                                onAction: function (_) {
                                    window.location.href = Routing.generate('model_custom_blocks_create', {}, false);
                                }
                            });
                        }

                        if (systemVariablesEnabled) {
                            editor.ui.registry.addMenuButton('bbcodesSection', {
                                text: 'MARS ZMIENNE (BBCODE)',
                                fetch: function (callback) {
                                    let items = [
                                        {
                                            type: 'menuitem',
                                            text: 'Nazwa modelu ([mars:model:name:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:name:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Nazwa SKU ([mars:sku:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:sku:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Nazwa SKU (skrócona) ([mars:sku:shortname])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:sku:shortname]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Procesor (CPU)',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:sku:cpu:name:name] [mars:sku:cpu:speed:name] [mars:sku:cpu:speedturbo:name] [mars:sku:cpu:cores:name] [mars:sku:cpu:cache:name] [mars:sku:cpu:socket:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Dysk twardy (HDD)',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:sku:hddcapacity:name] [mars:sku:hddcapacity:hddtype:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Kondycja ([mars:sku:grade:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:sku:grade:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Opis kondycji ([mars:sku:grade:description])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:sku:grade:description]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Seria ([mars:model:series:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:series:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Producent ([mars:model:manufacturer:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:manufacturer:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Lista Interfejsów ([mars:model:interfaces:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:interfaces:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Format wyświetlacza ([mars:model:displayformat:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:displayformat:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Typ wyświetlacza ([mars:model:screentype:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:screentype:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Powłoka wyświetlacza ([mars:model:displayshell:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:displayshell:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Rozdzielczość ekranu ([mars:model:screenresolution:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:screenresolution:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Przekątna ekranu ([mars:model:screensize:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:screensize:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'CDROM ([mars:model:hasopticaldrive])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:hasopticaldrive]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Ekran dotykowy ([mars:model:touchscreen:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:touchscreen:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Kamera ([mars:sku:camera:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:sku:camera:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Opis kamery ([mars:sku:camera:description])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:sku:camera:description]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Podświetlenie ([mars:model:backlight:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:backlight:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Pojemność baterii ([mars:model:batterycapacity:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:batterycapacity:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Licencja ([mars:sku:license:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:sku:license:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Opis licencji ([mars:sku:license:description])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:sku:license:description]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'System operacyjny ([mars:sku:license:operatingsystem:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:sku:license:operatingsystem:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Typ laptopa (Allegro) ([mars:model:allegrolaptoptype:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:allegrolaptoptype:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Typ komputera (Allegro) ([mars:model:allegropctype:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:allegropctype:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Typ obudowy ([mars:model:casetype:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:casetype:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Kolor obudowy ([mars:model:casecolor:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:casecolor:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Materiał (Allegro) ([mars:model:allegromaterial:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:allegromaterial:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Szerokość ([mars:model:width])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:width]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Wysokość ([mars:model:height])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:height]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Długość ([mars:model:length])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:length]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Waga ([mars:model:weight])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:weight]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Maksymalna pamięć RAM ([mars:model:rammaxsize:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:rammaxsize:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Typ RAM ([mars:model:ramtype:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:ramtype:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Ilość slotów RAM ([mars:model:ramslots:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:ramslots:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Pamięć RAM ([mars:sku:ramcapacity:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:sku:ramcapacity:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'List interfejsów dysku twardego ([mars:model:hddinterface:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:hddinterface:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Komunikacja (Allegro) ([mars:model:allegrocommunication:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:allegrocommunication:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Sterowanie (Allegro) ([mars:model:allegrocontrols:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:allegrocontrols:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Multimedia (Allegro) ([mars:model:allegromultimedia:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:allegromultimedia:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Typ karty graficznej (GPU) ([mars:model:allegrogpu:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:allegrogpu:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Typ podświetlenia ([mars:model:backlighttype:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:backlighttype:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Częstotliwość odświeżania obrazu ([mars:model:displayrefreshrate:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:displayrefreshrate:name]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: 'Kontrast statyczny obrazu ([mars:model:screencontrast:name])',
                                            onAction: function (_) {
                                                editor.insertContent('[mars:model:screencontrast:name]');
                                            }
                                        },
                                    ];

                                    callback(items);
                                }
                            });
                        }
                    }
                };

                this.createTinyMce(settings);
            });
        }
    }
}
